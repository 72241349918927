import React, { useState, useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import { fetchProductById } from "../services/productService";
import { ChevronLeftIcon, ChevronRightIcon, ShoppingCartIcon, CheckIcon, PlusIcon, MinusIcon } from "@heroicons/react/24/solid";
import { useCart } from "../contexts/CartContext";

const ProductDetailPage = () => {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isEnlarged, setIsEnlarged] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const { id } = useParams();
  const { dispatch } = useCart();

  useEffect(() => {
    const loadProduct = async () => {
      try {
        const fetchedProduct = await fetchProductById(id);
        if (fetchedProduct) {
          setProduct(fetchedProduct);
        } else {
          setError("Product not found");
        }
      } catch (error) {
        console.error('Error fetching product:', error);
        setError("An error occurred while fetching the product");
      } finally {
        setLoading(false);
      }
    };
    loadProduct();
  }, [id]);

  const handleAddToCart = () => {
    if (product) {
      dispatch({
        type: "ADD_ITEM",
        payload: {
          id: product._id,
          name: product.name,
          price: product.price,
          imageUrl: product.images[0],
          quantity: quantity
        },
      });
      setAddedToCart(true);
      setTimeout(() => setAddedToCart(false), 2000); // Reset after 2 seconds
    }
  };

  const handleQuantityChange = (change) => {
    setQuantity(prevQuantity => Math.max(1, prevQuantity + change));
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % product.images.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + product.images.length) % product.images.length);
  };

  const toggleEnlarged = () => {
    setIsEnlarged(!isEnlarged);
  };

  if (loading) return <div className="text-center py-10">Indlæser produktdetaljer...</div>;
  if (error) return <Navigate to="/404" />;
  if (!product) return null;

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="bg-cosmic-latte rounded-lg shadow-lg p-6">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Left Side - Image Gallery */}
          <div className="md:w-1/2">
            <div className="relative">
              <img
                src={product.images[currentImageIndex]}
                alt={product.name}
                className="w-full h-[300px] md:h-[500px] object-cover rounded-lg cursor-pointer"
                onClick={toggleEnlarged}
              />
              <button
                onClick={handlePrevImage}
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-cosmic-latte rounded-full p-2 shadow-md"
              >
                <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
              </button>
              <button
                onClick={handleNextImage}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-cosmic-latte rounded-full p-2 shadow-md"
              >
                <ChevronRightIcon className="h-6 w-6 text-gray-600" />
              </button>
            </div>
            <div className="mt-4 flex space-x-2 overflow-x-auto">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.name} thumbnail ${index + 1}`}
                  className={`w-16 h-16 md:w-20 md:h-20 object-cover rounded-md cursor-pointer ${
                    index === currentImageIndex
                      ? "border-2 border-primary-green"
                      : ""
                  }`}
                  onClick={() => setCurrentImageIndex(index)}
                />
              ))}
            </div>
          </div>

          {/* Right Side - Product Details */}
          <div className="md:w-1/2 px-4 md:px-8">
            <h1 className="text-2xl md:text-3xl font-bold text-primary-green mb-4">
              {product.name}
            </h1>
            <p className="text-gray-600 text-base md:text-lg mb-4">{product.category}</p>
            <p className="text-[#2d836a] font-normal text-xl md:text-2xl mb-6">
              {product.price} DKK
            </p>

            <div className="mb-6">
              <h2 className="text-lg md:text-xl font-semibold text-primary-green mb-2">
                Beskrivelse
              </h2>
              <p className="text-gray-700 text-sm md:text-base">{product.description}</p>
            </div>

            {product.specifications && (
              <div className="mb-6">
                <h2 className="text-lg md:text-xl font-semibold text-primary-green mb-2">
                  Specifikationer
                </h2>
                <ul className="list-disc list-inside text-gray-700 text-sm md:text-base">
                  {product.specifications.map((spec, index) => (
                    <li key={index}>{spec}</li>
                  ))}
                </ul>
              </div>
            )}

            <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 mb-4">
              <div className="flex items-center bg-light-green-50 rounded-full">
                <button
                  onClick={() => handleQuantityChange(-1)}
                  className="w-10 h-10 flex items-center justify-center text-primary-green hover:bg-green-100 rounded-full transition-colors duration-200"
                >
                  <MinusIcon className="h-5 w-5" />
                </button>
                <span className="w-10 text-center text-primary-green">{quantity}</span>
                <button
                  onClick={() => handleQuantityChange(1)}
                  className="w-10 h-10 flex items-center justify-center text-primary-green hover:bg-green-100 rounded-full transition-colors duration-200"
                >
                  <PlusIcon className="h-5 w-5" />
                </button>
              </div>
              <button
                onClick={handleAddToCart}
                className={`w-full sm:w-auto px-6 py-3 sm:px-4 sm:py-4 bg-green-button-color text-cosmic-latte rounded-full hover:bg-opacity-90 transition-all duration-300 text-base md:text-lg font-semibold flex items-center justify-center shadow-lg ${
                  addedToCart ? 'bg-primary-green' : ''
                }`}
              >
                {addedToCart ? <CheckIcon className="h-6 w-6 mr-2" /> : <ShoppingCartIcon className="h-6 w-6 mr-2" />}
                <span>{addedToCart ? 'Tilføjet' : 'Læg i kurv'}</span>
              </button>
            </div>
            <p className={`text-primary-green font-semibold text-center sm:text-left transition-opacity duration-300 ${
              addedToCart ? 'opacity-100' : 'opacity-0'
            }`}>
              {quantity} {quantity === 1 ? 'produkt' : 'produkter'} tilføjet til kurven!
            </p>
          </div>
        </div>
      </div>

      {/* Enlarged Image Modal */}
      {isEnlarged && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={toggleEnlarged}
        >
          <img
            src={product.images[currentImageIndex]}
            alt={product.name}
            className="max-w-full max-h-full object-contain"
          />
        </div>
      )}
    </div>
  );
};

export default ProductDetailPage;
import React from 'react';
import SideNavigation from './SideNavigation';
import TopRight from './TopRight';
import logoImage from '../../assets/images/welovebirdsskriftlogo.png';
import styled from 'styled-components';

// Create a styled component for the logo container
const LogoContainer = styled.div`
  position: fixed;
  top: 40px; // Changed from 0 to 20px
  left: 0;
  right: 0;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 10;

  @media (max-width: 480px) {
    height: 100px;
  }
`;

// Create a styled component for the logo
const LogoImage = styled.img`
  max-width: 70%;
  height: auto;
  max-height: 5rem;

  @media (max-width: 1024px) {
    max-width: 80%;
    max-height: 4.5rem;
  }

  @media (max-width: 768px) {
    max-width: 85%;
    max-height: 4rem;
  }

  @media (max-width: 480px) {
    max-width: 75%;
    max-height: 3rem;
  }
`;

function Header() {
  return (
    <>
      <LogoContainer>
        <LogoImage src={logoImage} alt="We Love Birds" className="opacity-80" />
      </LogoContainer>
      <header className="fixed top-0 left-0 right-0 z-30">
        <div className="relative flex justify-between items-center px-4 py-4">
          {/* Side Navigation */}
          <div className="z-50">
            <SideNavigation />
          </div>
          {/* Top Right Component */}
          <div className="w-1/4 md:w-80 z-40 flex justify-end">
            <TopRight />
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
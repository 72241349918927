import React, { useEffect, useState } from 'react';
import { EnvelopeIcon, PhoneIcon, ClockIcon, MapPinIcon } from '@heroicons/react/24/outline';
import sanityClient from '../services/sanityClient';

function ContactPage() {
  const [contactInfo, setContactInfo] = useState(null);

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const data = await sanityClient.fetch(`*[_type == "contactInfo"][0]`);
        setContactInfo(data);
      } catch (error) {
        console.error('Error fetching contact info:', error);
      }
    };

    fetchContactInfo();
  }, []);

  if (!contactInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-light-green-50 max-w-2xl mx-auto rounded-3xl shadow-lg overflow-hidden">
        <div className="py-12 sm:py-16">
          <div className="container mx-auto px-6 sm:px-8 text-center">
            <h1 className="text-4xl sm:text-5xl font-bold mb-3 sm:mb-4 text-primary-green">Kontakt Os</h1>
            <p className="text-xl sm:text-2xl text-forest-green">Vi er her for at hjælpe</p>
          </div>
        </div>

        <div className="container mx-auto px-6 sm:px-8 py-8 sm:py-12">
          <section className="mb-12 sm:mb-16">
            <h2 className="text-2xl sm:text-3xl font-semibold text-primary-green mb-6 sm:mb-8">Vores Kontaktoplysninger</h2>
            <div className="grid sm:grid-cols-2 gap-6 sm:gap-8">
              <div className="flex items-start">
                <EnvelopeIcon className="h-6 w-6 sm:h-8 sm:w-8 text-primary-green mr-3 sm:mr-4 flex-shrink-0" />
                <div>
                  <h3 className="text-lg sm:text-xl font-semibold mb-1 sm:mb-2">E-mail</h3>
                  <p className="text-base sm:text-lg mb-1 sm:mb-2">{contactInfo.email}</p>
                  <p className="text-sm sm:text-base text-gray-600">{contactInfo.emailResponseTime}</p>
                </div>
              </div>
              <div className="flex items-start">
                <PhoneIcon className="h-6 w-6 sm:h-8 sm:w-8 text-primary-green mr-3 sm:mr-4 flex-shrink-0" />
                <div>
                  <h3 className="text-lg sm:text-xl font-semibold mb-1 sm:mb-2">Telefon</h3>
                  <p className="text-base sm:text-lg mb-1 sm:mb-2">{contactInfo.phone}</p>
                  <p className="text-sm sm:text-base text-gray-600">{contactInfo.phoneDescription}</p>
                </div>
              </div>
              <div className="flex items-start">
                <ClockIcon className="h-6 w-6 sm:h-8 sm:w-8 text-primary-green mr-3 sm:mr-4 flex-shrink-0" />
                <div>
                  <h3 className="text-lg sm:text-xl font-semibold mb-1 sm:mb-2">Telefontider</h3>
                  {contactInfo.businessHours.map((hours, index) => (
                    <p key={index} className="text-base sm:text-lg">{hours.days}: {hours.hours}</p>
                  ))}
                </div>
              </div>
              <div className="flex items-start">
                <MapPinIcon className="h-6 w-6 sm:h-8 sm:w-8 text-primary-green mr-3 sm:mr-4 flex-shrink-0" />
                <div>
                  <h3 className="text-lg sm:text-xl font-semibold mb-1 sm:mb-2">Adresse</h3>
                  <p className="text-base sm:text-lg">{contactInfo.address.street}</p>
                  <p className="text-base sm:text-lg">{contactInfo.address.postalCode} {contactInfo.address.city}</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
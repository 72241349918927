import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../../contexts/CartContext';
import { ShoppingCartIcon, PlusIcon } from '@heroicons/react/24/outline';

const ProductCard = ({ product }) => {
  const { dispatch } = useCart();

  const handleAddToCart = (e) => {
    e.preventDefault(); // Prevent navigation when clicking the add to cart button
    dispatch({ 
      type: 'ADD_ITEM', 
      payload: {
        id: product._id,
        name: product.name,
        price: product.price,
        imageUrl: product.imageUrl
      }
    });
  };

  return (
    <Link to={`/product/${product._id}`} className="group">
      <div className="bg-light-green-50 rounded-2xl shadow-md overflow-hidden transition-all duration-300 transform hover:-translate-y-2 hover:shadow-xl">
        <div className="relative">
          <img 
            src={product.imageUrl} 
            alt={product.name} 
            className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-primary-green bg-opacity-20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
            <button 
              onClick={handleAddToCart}
              className="bg-green-button-color text-cosmic-latte p-3 rounded-full hover:bg-opacity-90 transition-all duration-200 transform translate-y-4 group-hover:translate-y-0 shadow-lg"
              aria-label="Add to cart"
            >
              <ShoppingCartIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
        <div className="p-4">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-semibold text-primary-green truncate">{product.name}</h3>
            <span className="text-green-button-color font-bold text-lg">{product.price} DKK</span>
          </div>
          <p className="text-sm text-gray-600 mb-3 truncate">{product.category}</p>
          <div className="flex items-center justify-between">
            <span className="text-sm text-primary-green font-medium">Se detaljer</span>
            <div className="bg-cosmic-latte rounded-full p-1">
              <PlusIcon className="h-5 w-5 text-green-button-color" />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;
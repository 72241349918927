import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { calculateHmacSha1 } from "../utils/hmacCalculation";
import {
  XCircleIcon,
  EnvelopeIcon,
  CreditCardIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import Lottie from "lottie-react";
import checkAnimation from "../assets/images/check-animation.json";

const OrderConfirmationPage = () => {
  const location = useLocation();
  const [isPaymentVerified, setIsPaymentVerified] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const verifyPayment = () => {
      const queryParams = new URLSearchParams(location.search);

      // Check for test mode
      if (queryParams.get("test") === "true") {
        console.log("Test mode activated");
        setIsPaymentVerified(true);
        setPaymentDetails({
          amount: "100000", // 1000.00 in minor units
          currency: "DKK",
          reference: "TEST-" + Date.now(),
          status: "Success",
          errorCode: "0",
        });
        return;
      }

      // Real payment verification
      const params = {};
      for (let [key, value] of queryParams.entries()) {
        if (key.startsWith("onpay_") && key !== "onpay_hmac_sha1") {
          params[key] = value;
        }
      }

      console.log("Payment params:", params);

      const secret = process.env.REACT_APP_ONPAY_SECRET;
      const calculatedHmac = calculateHmacSha1(params, secret);
      const receivedHmac = queryParams.get("onpay_hmac_sha1");

      if (calculatedHmac === receivedHmac) {
        console.log("Payment verified successfully");
        setIsPaymentVerified(true);
        setPaymentDetails({
          amount: params.onpay_amount,
          currency:
            params.onpay_currency === "208"
              ? "DKK"
              : params.onpay_currency || "DKK",
          reference: params.onpay_reference,
          status: params.onpay_errorcode === "0" ? "Success" : "Failed",
          errorCode: params.onpay_errorcode,
        });
      } else {
        console.error("HMAC verification failed");
        setError("Payment verification failed. Please contact support.");
      }
    };

    verifyPayment();
  }, [location.search]);

  const formatAmount = (amount, currency) => {
    try {
      const numericAmount = Number(amount) / 100;
      return numericAmount.toLocaleString("da-DK", {
        style: "currency",
        currency: currency === "208" ? "DKK" : currency || "DKK",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } catch (e) {
      console.error("Error formatting amount:", e);
      // Fallback to basic formatting if toLocaleString fails
      return `${(Number(amount) / 100).toFixed(2).replace(".", ",")} ${
        currency === "208" ? "DKK" : currency || "DKK"
      }`;
    }
  };

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8 max-w-3xl">
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  if (!isPaymentVerified) {
    return (
      <div className="container mx-auto px-4 py-8 max-w-3xl">
        <div className="bg-cosmic-latte p-8 rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold text-primary-green mb-4 text-center">
            Verificerer betaling...
          </h1>
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-green"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <div className="bg-cosmic-latte p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-primary-green mb-8 text-center">
          Ordre Bekræftelse
        </h1>
        {paymentDetails && (
          <div className="space-y-6">
            <div className="flex justify-center">
              {paymentDetails.status === "Success" ? (
                <Lottie
                  animationData={checkAnimation}
                  loop={false}
                  style={{ width: 150, height: 150 }}
                />
              ) : (
                <XCircleIcon className="h-24 w-24 text-red-500" />
              )}
            </div>
            <h2 className="text-2xl font-semibold text-center mb-6">
              {paymentDetails.status === "Success"
                ? "Tak for dit køb!"
                : "Betalingen mislykkedes"}
            </h2>
            <div className="p-6 rounded-lg shadow-inner">
              <div className="space-y-4">
                <div className="flex items-center">
                  <CreditCardIcon className="h-6 w-6 text-forest-green mr-2" />
                  <span className="text-gray-600 mr-2">Beløb godkendt:</span>
                  <span className="font-semibold">
                    {formatAmount(
                      paymentDetails.amount,
                      paymentDetails.currency
                    )}
                  </span>
                </div>
                <div className="flex items-center">
                  <EnvelopeIcon className="h-6 w-6 text-forest-green mr-2" />
                  <span className="text-gray-600">
                    En bekræftelsesmail er sendt til din e-mailadresse
                  </span>
                </div>
                <div className="flex items-center">
                  <TruckIcon className="h-6 w-6 text-forest-green mr-2" />
                  <span className="text-gray-600">
                    Forventet leveringstid: 3-5 hverdage
                  </span>
                </div>
              </div>
            </div>
            {paymentDetails.status === "Success" && (
              <div className="text-center mt-8">
                <p className="text-lg text-gray-700 mb-4">
                  Din ordre er nu bekræftet og vil blive behandlet hurtigst
                  muligt. Du vil modtage en e-mail med ordredetaljer og tracking
                  information, når din ordre er afsendt.
                </p>
                <button
                  onClick={() => (window.location.href = "/")}
                  className="bg-green-button-color text-cosmic-latte px-6 py-2 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-lg font-semibold"
                >
                  Fortsæt med at handle
                </button>
              </div>
            )}
            {paymentDetails.status !== "Success" && (
              <div className="text-center mt-8">
                <p className="text-lg text-gray-700 mb-4">
                  Der opstod desværre en fejl under betalingen. Ingen penge er
                  trukket fra din konto. Prøv venligst igen eller kontakt
                  kundeservice, hvis problemet fortsætter.
                </p>
                <button
                  onClick={() => (window.location.href = "/checkout")}
                  className="bg-green-button-color text-cosmic-latte px-6 py-2 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-lg font-semibold"
                >
                  Prøv igen
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderConfirmationPage;

import sanityClient from './sanityClient';


export const createUser = async (userData) => {
  try {
    const result = await sanityClient.create({
      _type: 'user',
      auth0Id: userData.sub,
      email: userData.email,
      username: userData.nickname || userData.name,
    });
    console.log('User created in Sanity:', result);
    return result;
  } catch (error) {
    console.error('Error creating user in Sanity:', error);
    throw new Error('Failed to create user in Sanity');
  }
};
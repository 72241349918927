import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ShoppingCartIcon, UserIcon } from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";
import CartMenu from "./../Cart/CartMenu";
import { useCart } from "../../contexts/CartContext";

function TopRight() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const { state } = useCart();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleCart = () => setIsCartOpen(!isCartOpen);

  const cartItemsCount = state.items.reduce(
    (total, item) => total + item.quantity,
    0
  );

  return (
    <div className="flex items-center space-x-2 sm:space-x-4">
      <button
        onClick={isAuthenticated ? toggleMenu : loginWithRedirect}
        className="relative"
      >
        <div
          className={`relative bg-cosmic-latte p-1.5 sm:p-2 rounded-full shadow-md hover:shadow-xl transition-shadow duration-300 ${
            isAuthenticated ? "border-2 border-primary-green" : ""
          }`}
        >
          <div className="absolute inset-0 rounded-full scale-125 bg-light-green-50"></div>
          <UserIcon className="relative h-5 w-5 sm:h-6 sm:w-6 md:h-8 md:w-8 text-primary-green" />
        </div>
      </button>
      <button onClick={toggleCart} className="relative">
        <div className="relative bg-cosmic-latte p-1.5 sm:p-2 rounded-full shadow-md hover:shadow-xl transition-shadow duration-300">
          <div className="absolute inset-0 rounded-full scale-125 bg-light-green-50"></div>
          <ShoppingCartIcon className="relative h-5 w-5 sm:h-6 sm:w-6 md:h-8 md:w-8 text-primary-green" />
          {cartItemsCount > 0 && (
            <span className="absolute -top-1 -right-1 bg-red-500 text-cosmic-latte rounded-full w-4 h-4 sm:w-5 sm:h-5 flex items-center justify-center text-[10px] sm:text-xs">
              {cartItemsCount}
            </span>
          )}
        </div>
      </button>

      {/* User menu */}
      {isMenuOpen && isAuthenticated && (
        <div className="absolute right-0 top-full mt-0 w-48 bg-cosmic-latte rounded-md shadow-lg py-1 z-50">
          <Link
            to="/profile"
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            onClick={toggleMenu}
          >
            Konto
          </Link>
          <button
            onClick={() => {
              logout({ returnTo: window.location.origin });
              toggleMenu();
            }}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Log ud
          </button>
        </div>
      )}

      {/* Cart Menu */}
      <CartMenu
        isOpen={isCartOpen}
        onClose={toggleCart}
        className="absolute right-0 top-full mt-0 z-50"
      />
    </div>
  );
}

export default TopRight;
import React from 'react';
import ProductList from '../components/Product/ProductList';
import styled from 'styled-components';

const PageWrapper = styled.div`
  position: relative;
  z-index: 30; // Higher than the MainContent's z-index
`;

function ShopPage() {
  return (
    <PageWrapper>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-primary-green mb-8 text-center">Vores Produkter</h1>
        <div className="rounded-lg">
          <ProductList />
        </div>
      </div>
    </PageWrapper>
  );
}

export default ShopPage;
import React, { useState, useEffect } from 'react';
import { fetchUserPosts } from '../services/userPostService';
import UserPostCard from '../components/UserPosts/UserPostCard';
import CreateUserPostForm from '../components/UserPosts/CreateUserPostForm';
import { useAuth0 } from '@auth0/auth0-react';
import { PlusIcon } from '@heroicons/react/24/solid';

function UserPostsPage() {
    const [userPosts, setUserPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const { isAuthenticated, loginWithRedirect } = useAuth0();
  
    useEffect(() => {
      const loadUserPosts = async () => {
        try {
          const posts = await fetchUserPosts();
          setUserPosts(posts || []);
          setLoading(false);
        } catch (err) {
          console.error('Error fetching user posts:', err);
          setError(err.message);
          setLoading(false);
        }
      };
      loadUserPosts();
    }, []);
  
    if (loading) {
      return <div className="text-center py-10">Indlæser brugeropslag...</div>;
    }
  
    if (error) {
      return <div className="text-center py-10 text-red-500">Fejl: {error}</div>;
    }
  
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-light-green-50 rounded-lg shadow-md p-6 mb-8">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
            <h1 className="text-3xl font-bold text-primary-green mb-4 sm:mb-0">Undulat Markedsplads</h1>
            {isAuthenticated ? (
              <button
                onClick={() => setShowCreateForm(!showCreateForm)}
                className="bg-green-button-color text-cosmic-latte px-4 py-2 rounded-lg hover:bg-opacity-90 transition-colors duration-200 flex items-center mt-4 sm:mt-0"
              >
                <PlusIcon className="h-5 w-5 mr-2" />
                {showCreateForm ? 'Annuller' : 'Opret nyt opslag'}
              </button>
            ) : (
              <button
                onClick={() => loginWithRedirect()}
                className="bg-green-button-color text-cosmic-latte px-4 py-2 rounded-lg hover:bg-opacity-90 transition-colors duration-200 mt-4 sm:mt-0"
              >
                Log ind for at oprette opslag
              </button>
            )}
          </div>
          <p className="mt-4 text-gray-600">
            Velkommen til Undulat Markedspladsen! Her kan du købe, sælge eller bytte alt relateret til undulater.
          </p>
        </div>

        {showCreateForm && (
          <div className="mb-8">
            <CreateUserPostForm />
          </div>
        )}

        <div className="space-y-8">
          {userPosts.length === 0 ? (
            <div className="text-center py-10 bg-cosmic-latte rounded-lg shadow-md">
              <p className="text-xl text-primary-green">Der er ingen opslag at vise i øjeblikket.</p>
              {isAuthenticated && (
                <p className="mt-2 text-gray-600">Vær den første til at oprette et opslag!</p>
              )}
            </div>
          ) : (
            userPosts.map((post) => (
              <UserPostCard key={post._id} post={post} />
            ))
          )}
        </div>
      </div>
    );
  }
  
  export default UserPostsPage;
import React from "react";
import {
  PlusIcon,
  MinusIcon,
  TrashIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import RoundButton from "./RoundButton";

const CartReview = ({ cartItems, dispatch, handleNextStep }) => {
  const updateQuantity = (id, newQuantity) => {
    if (newQuantity === 0) {
      dispatch({ type: "REMOVE_ITEM", payload: id });
    } else {
      dispatch({
        type: "UPDATE_QUANTITY",
        payload: { id, quantity: newQuantity },
      });
    }
  };

  const removeItem = (id) => {
    dispatch({ type: "REMOVE_ITEM", payload: id });
  };

  const totalPrice = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  return (
    <div>
      <h2 className="text-2xl font-semibold text-primary-green mb-4">
        Gennemgå din indkøbskurv
      </h2>
      {cartItems.length === 0 ? (
        <p>Din indkøbskurv er tom.</p>
      ) : (
        <>
          {cartItems.map((item) => (
            <div
              key={item.id}
              className="flex items-center justify-between mb-4 pb-4 border-b"
            >
              <div className="flex items-center">
                <img
                  src={item.imageUrl}
                  alt={item.name}
                  className="w-16 h-16 object-cover rounded-md mr-4"
                />
                <div>
                  <h3 className="font-semibold">{item.name}</h3>
                  <p className="text-gray-600">{item.price} DKK</p>
                </div>
              </div>
              <div className="flex items-center">
                <button
                  onClick={() => updateQuantity(item.id, item.quantity - 1)}
                  className="bg-light-green-50 text-primary-green rounded-full p-2 hover:bg-green-100 transition-colors duration-200"
                  aria-label="Decrease quantity"
                >
                  <MinusIcon className="h-5 w-5" />
                </button>
                <span className="mx-3 font-semibold">{item.quantity}</span>
                <button
                  onClick={() => updateQuantity(item.id, item.quantity + 1)}
                  className="bg-light-green-50 text-primary-green rounded-full p-2 hover:bg-green-100 transition-colors duration-200"
                  aria-label="Increase quantity"
                >
                  <PlusIcon className="h-5 w-5" />
                </button>
                <button
                  onClick={() => removeItem(item.id)}
                  className="ml-4 bg-red-100 text-red-500 rounded-full p-2 hover:bg-red-200 transition-colors duration-200"
                  aria-label="Remove item"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          ))}
          <div className="mt-6 text-right">
            <p className="text-xl font-semibold">
              Total: {totalPrice.toFixed(2)} DKK
            </p>
          </div>
          <div className="mt-6 flex justify-end items-center">
            <span className="text-lg font-semibold text-primary-green mr-4">
              Gå til levering
            </span>
            <RoundButton
              onClick={handleNextStep}
              ariaLabel="Fortsæt til levering"
              className="bg-green-button-color text-cosmic-latte hover:bg-opacity-90"
              icon={ArrowRightIcon}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CartReview;

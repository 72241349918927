import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../services/sanityClient";
import { PortableText } from "@portabletext/react";

function HomePage() {
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "homePage"][0]{
          title,
          welcomeSection,
          sections
        }`
      )
      .then((data) => setPageData(data))
      .catch(console.error);
  }, []);

  if (!pageData) return <div>Loading...</div>;

  return (
    <div className="container mx-auto px-4">
      {/* Velkomstsektion */}
      <section
        className="text-center mt-32 mb-16"
        style={{ marginBottom: "300px" }}
      >
        <h2 className="text-4xl font-bold text-primary-green mb-4">
          {pageData.welcomeSection.heading}
        </h2>
        <p className="text-lg text-gray-700">
          {pageData.welcomeSection.subheading}
        </p>
      </section>

      {/* Dynamiske sektioner */}
      <div className="flex flex-col items-center">
        {pageData.sections &&
          pageData.sections.map((section, index) => (
            <section key={index} className="my-16 max-w-2xl w-full text-center">
              <h3 className="text-2xl font-bold text-primary-green mb-6">
                {section.heading}
              </h3>
              <div className="prose prose-lg mx-auto">
                <PortableText value={section.content} />
              </div>
            </section>
          ))}
      </div>

      {/* Nyhedsbrevstilmelding */}
      <section className="flex flex-col items-center my-16">
        <h3 className="text-xl font-bold text-primary-green mb-4">
          Tilmeld dig vores nyhedsbrev
        </h3>
        <p className="text-sm text-gray-700 mb-4 text-center">
          Få de seneste nyheder om fugle, arrangementer og tilbud direkte i din
          indbakke.
        </p>
        <form className="w-full max-w-md flex flex-col items-center">
          <input
            type="email"
            placeholder="Indtast din e-mail"
            className="w-full bg-cosmic-latte text-gray-900 rounded-lg px-4 py-2 mb-4 focus:outline-none focus:ring-2 focus:ring-green-button-color"
            required
          />
          <button
            type="submit"
            className="bg-green-button-color text-cosmic-latte px-6 py-2 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-sm font-bold"
          >
            Tilmeld dig
          </button>
        </form>
      </section>

      {/* Sponsorsamarbejde sektion */}
      <div className="flex justify-center pb-8">
        <p className="text-sm text-gray-800 bg-cosmic-latte px-4 py-2 rounded-lg shadow-md text-center">
          Vi samarbejder med{" "}
          <Link
            to="/zoo-samarbejde"
            className="text-primary-green font-bold hover:underline"
          >
            Zoologisk Have i København
          </Link>{" "}
          om at sponsorere bevaringen af forskellige dyrearter.
        </p>
      </div>
    </div>
  );
}

export default HomePage;

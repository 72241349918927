import React from "react";
import RoundButton from "./RoundButton";

const ShippingForm = ({
  shippingInfo,
  handleShippingInfoChange,
  handleNextStep,
  handlePreviousStep,
  userInfo,
}) => {
  return (
    <div className="flex flex-col md:flex-row md:space-x-8">
      {/* Left side - User information */}
      <div className="md:w-1/3 mb-6 md:mb-0 bg-light-green-50 p-6 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-primary-green mb-4 font-Fredoka">
          Dine kontooplysninger
        </h3>
        <div>
          <div className="mb-4">
            <p className="text-lg text-primary-green font-semibold">Navn:</p>
            <p className="text-lg text-placeholder-green">{userInfo.name}</p>
          </div>
          <div>
            <p className="text-lg text-primary-green font-semibold">Email:</p>
            <p className="text-lg text-placeholder-green">{userInfo.email}</p>
          </div>
        </div>
      </div>

      {/* Right side - Shipping and billing information */}
      <div className="md:w-2/3 bg-cosmic-latte p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-primary-green mb-4 font-Fredoka">
          Leverings- og faktureringsoplysninger
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleNextStep();
          }}
        >
          <h3 className="text-xl font-semibold text-primary-green mb-4">
            Leveringsadresse
          </h3>
          {/* Leveringsadresse felter */}
          <div className="mb-4">
            <label
              htmlFor="fullName"
              className="block text-sm font-medium text-gray-700"
            >
              Fulde navn
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={shippingInfo.fullName}
              onChange={handleShippingInfoChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-button-color focus:ring focus:ring-green-button-color focus:ring-opacity-50 p-2"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="address"
              className="block text-sm font-medium text-gray-700"
            >
              Adresse
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={shippingInfo.address}
              onChange={handleShippingInfoChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-button-color focus:ring focus:ring-green-button-color focus:ring-opacity-50 p-2"
            />
          </div>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700"
              >
                By
              </label>
              <input
                type="text"
                id="city"
                name="city"
                value={shippingInfo.city}
                onChange={handleShippingInfoChange}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-button-color focus:ring focus:ring-green-button-color focus:ring-opacity-50 p-2"
              />
            </div>
            <div>
              <label
                htmlFor="postalCode"
                className="block text-sm font-medium text-gray-700"
              >
                Postnummer
              </label>
              <input
                type="text"
                id="postalCode"
                name="postalCode"
                value={shippingInfo.postalCode}
                onChange={handleShippingInfoChange}
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-button-color focus:ring focus:ring-green-button-color focus:ring-opacity-50 p-2"
              />
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={shippingInfo.email}
              onChange={handleShippingInfoChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-button-color focus:ring focus:ring-green-button-color focus:ring-opacity-50 p-2"
            />
          </div>

          <div className="mb-6">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="sameAsBilling"
                checked={shippingInfo.sameAsBilling}
                onChange={handleShippingInfoChange}
                className="rounded border-gray-300 text-green-button-color shadow-sm focus:border-green-button-color focus:ring focus:ring-green-button-color focus:ring-opacity-50"
              />
              <span className="ml-2 text-sm text-gray-700">
                Faktureringsadresse er den samme som leveringsadresse
              </span>
            </label>
          </div>

          {!shippingInfo.sameAsBilling && (
            <>
              <h3 className="text-xl font-semibold text-primary-green mb-4">
                Faktureringsadresse
              </h3>
              {/* Faktureringsadresse felter */}
              <div className="mb-4">
                <label
                  htmlFor="billingFullName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Fulde navn
                </label>
                <input
                  type="text"
                  id="billingFullName"
                  name="billingFullName"
                  value={shippingInfo.billingFullName}
                  onChange={handleShippingInfoChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-button-color focus:ring focus:ring-green-button-color focus:ring-opacity-50 p-2"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="billingAddress"
                  className="block text-sm font-medium text-gray-700"
                >
                  Adresse
                </label>
                <input
                  type="text"
                  id="billingAddress"
                  name="billingAddress"
                  value={shippingInfo.billingAddress}
                  onChange={handleShippingInfoChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-button-color focus:ring focus:ring-green-button-color focus:ring-opacity-50 p-2"
                />
              </div>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <label
                    htmlFor="billingCity"
                    className="block text-sm font-medium text-gray-700"
                  >
                    By
                  </label>
                  <input
                    type="text"
                    id="billingCity"
                    name="billingCity"
                    value={shippingInfo.billingCity}
                    onChange={handleShippingInfoChange}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-button-color focus:ring focus:ring-green-button-color focus:ring-opacity-50 p-2"
                  />
                </div>
                <div>
                  <label
                    htmlFor="billingPostalCode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Postnummer
                  </label>
                  <input
                    type="text"
                    id="billingPostalCode"
                    name="billingPostalCode"
                    value={shippingInfo.billingPostalCode}
                    onChange={handleShippingInfoChange}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-button-color focus:ring focus:ring-green-button-color focus:ring-opacity-50 p-2"
                  />
                </div>
              </div>
            </>
          )}

          {/* Navigation buttons */}
          <div className="mt-6 flex justify-between items-center">
            <div className="flex items-center">
              <RoundButton
                onClick={handlePreviousStep}
                ariaLabel="Tilbage til kurv"
                className="bg-gray-200 text-gray-800 hover:bg-gray-300"
                direction="left"
              />
              <span className="ml-4 text-lg font-semibold text-gray-700">
                Tilbage til kurv
              </span>
            </div>
            <div className="flex items-center">
              <span className="text-lg font-semibold text-primary-green mr-4">
                Gå til betaling
              </span>
              <RoundButton
                onClick={handleNextStep}
                ariaLabel="Gå til betaling"
                className="bg-green-button-color text-cosmic-latte hover:bg-opacity-90"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShippingForm;

import React, { useState, useEffect } from 'react';
import { RefreshCw, Check } from 'lucide-react';

const firstNames = ['Kvidre', 'Flakse', 'Næb', 'Fjer', 'Spurv', 'Ørn', 'Ugle', 'Papegøje', 'Ande', 'Piwi', 'Høge', 'Svale', 'Svan', 'Krage', 'Gøg', 'Fritte', 'Glente', 'Vinge', 'Fugle', 'Fuglix'];
const lastNames = ['Mester', 'Fjols', 'Ven', 'Flok', 'Snak', 'Dronning', 'Konge', 'Finder', 'Tosse', 'Fejl', 'Doktor', 'Geni', 'Guide', 'Kaptajn', 'Nørd', 'Sprinter', 'Rædsel', 'Krammer', 'Fænomen', 'Storm'];

const generateRandomUsername = () => {
  const firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
  return `${firstName}${lastName}`;
};

const UsernameGenerator = ({ initialUsername, onSaveUsername }) => {
  const [username, setUsername] = useState(initialUsername || '');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!initialUsername) {
      const newUsername = generateRandomUsername();
      setUsername(newUsername);
      onSaveUsername(newUsername);
    } else {
      setUsername(initialUsername);
    }
  }, [initialUsername, onSaveUsername]);

  const generateUsername = () => {
    const newUsername = generateRandomUsername();
    setUsername(newUsername);
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setUsername(e.target.value);
    setIsEditing(true);
  };

  const handleSave = () => {
    onSaveUsername(username);
    setIsEditing(false);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-2">
        <input
          type="text"
          value={username}
          onChange={handleInputChange}
          placeholder="Dit fugle-brugernavn"
          className="flex-grow p-2 border rounded bg-cosmic-latte"
        />
        <button 
          onClick={generateUsername}
          className="w-10 h-10 flex items-center justify-center bg-bark-brown text-cosmic-latte rounded-full hover:bg-opacity-90 transition-colors duration-200"
          aria-label="Generer nyt brugernavn"
        >
          <RefreshCw size={20} />
        </button>
        {isEditing && (
          <button 
            onClick={handleSave}
            className="w-10 h-10 flex items-center justify-center bg-green-button-color text-cosmic-latte rounded-full hover:bg-opacity-90 transition-colors duration-200"
            aria-label="Gem brugernavn"
          >
            <Check size={20} />
          </button>
        )}
      </div>
      <p className="text-sm text-gray-500">
        Vælg dit eget sjove fugle-brugernavn eller lad os generere et for dig!
      </p>
    </div>
  );
};

export default UsernameGenerator;
// src/pages/TermsPage.js
import React, { useState, useEffect } from 'react';
import sanityClient from '../services/sanityClient';
import { PortableText } from '@portabletext/react';

const TermsPage = () => {
  const [termsData, setTermsData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "termsAndConditions"][0]{
          title,
          sections
        }`
      )
      .then((data) => setTermsData(data))
      .catch(console.error);
  }, []);

  if (!termsData) return <div>Indlæser...</div>;

  return (
    <div className="container mx-auto px-8 sm:px-4 py-8"> {/* Increased left/right padding on mobile */}
      <div className="max-w-3xl mx-auto">
        <div className="bg-cosmic-latte rounded-lg shadow-lg overflow-hidden">
          <div className="bg-light-green-50 p-4 sm:p-6">
            <h1 className="text-2xl sm:text-3xl font-bold text-primary-green">{termsData.title}</h1>
          </div>
          <div className="p-4 sm:p-6">
            {termsData.sections.map((section, index) => (
              <div key={index} className="mb-6 sm:mb-8">
                <h2 className="text-xl sm:text-2xl font-semibold text-primary-green mb-3 sm:mb-4">{section.sectionTitle}</h2>
                <div className="prose max-w-none text-sm sm:text-base">
                  <PortableText value={section.content} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
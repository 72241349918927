import React, { useState, useRef, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { XCircleIcon, CloudArrowUpIcon } from "@heroicons/react/24/solid";
import sanityClient from "../../services/sanityClient";
import { v4 as uuidv4 } from "uuid";

const CreateUserPostForm = () => {
  const { user } = useAuth0();
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    images: [],
    contactInfo: {
      name: user?.name || "",
      city: "",
      zipCode: "",
      phone: "",
      email: user?.email || "",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setFormData((prev) => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value,
        },
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const imagePreviews = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setFormData((prev) => ({
      ...prev,
      images: [...prev.images, ...imagePreviews],
    }));
  };

  const removeImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      console.log("Preparing images for upload");
      const imageAssets = await Promise.all(
        formData.images.map(async (image, index) => {
          console.log(`Uploading image ${index + 1}`);
          const assetRef = await sanityClient.assets.upload(
            "image",
            image.file,
            {
              filename: `${uuidv4()}-${image.file.name}`,
            }
          );
          console.log(`Image ${index + 1} uploaded:`, assetRef);
          return {
            _type: "image",
            _key: uuidv4(), // Add a unique key for each image
            asset: {
              _type: "reference",
              _ref: assetRef._id,
            },
            ...(index === 0 ? { _key: "featuredImage" } : {}),
          };
        })
      );
      console.log("Image assets prepared:", imageAssets);

      console.log("Preparing document");
      const doc = {
        _type: "userPost",
        title: formData.title,
        description: formData.description,
        price: formData.price,
        featuredImage: imageAssets.find((img) => img._key === "featuredImage"),
        images: imageAssets.filter((img) => img._key !== "featuredImage"),
        contactInfo: formData.contactInfo,
        createdAt: new Date().toISOString(),
      };
      console.log("Document prepared:", doc);

      console.log("Creating document in Sanity");
      const result = await sanityClient.create(doc);
      console.log("User post created:", result);

      // Reset form
      setFormData({
        title: "",
        description: "",
        price: "",
        images: [],
        contactInfo: {
          name: user?.name || "",
          city: "",
          zipCode: "",
          phone: "",
          email: user?.email || "",
        },
      });

      // Show success message
      alert("Opslag oprettet med succes!");
    } catch (error) {
      console.error("Error creating user post:", error);
      setError(
        "Der opstod en fejl ved oprettelse af opslaget. Prøv venligst igen."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    return () => {
      formData.images.forEach((image) => URL.revokeObjectURL(image.preview));
    };
  }, [formData.images]);

  return (
    <div className="max-w-2xl mx-auto mt-10 bg-cosmic-latte p-8 rounded-xl shadow-lg">
      <h2 className="text-3xl font-bold text-primary-green mb-6">
        Opret nyt opslag
      </h2>
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <strong className="font-bold">Fejl!</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Titel
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-button-color"
          />
        </div>

        <div>
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Beskrivelse
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
            rows="5"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-button-color"
          ></textarea>
        </div>

        <div>
          <label
            htmlFor="price"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Pris
          </label>
          <input
            type="text"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-button-color"
          />
        </div>

        <div>
          <label
            htmlFor="images"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Billeder
          </label>
          <input
            type="file"
            id="images"
            name="images"
            ref={fileInputRef}
            onChange={handleImageChange}
            multiple
            accept="image/*"
            className="hidden"
          />
          <button
            type="button"
            onClick={() => fileInputRef.current.click()}
            className="w-full bg-green-button-color text-cosmic-latte px-4 py-2 rounded-lg hover:bg-opacity-90 transition-colors duration-200 flex items-center justify-center"
          >
            <CloudArrowUpIcon className="h-5 w-5 mr-2" />
            Vælg billeder
          </button>
          <p className="mt-1 text-sm text-gray-500">
            Det første billede vil være det fremhævede billede.
          </p>
          <div className="mt-4 grid grid-cols-3 gap-4">
            {formData.images.map((image, index) => (
              <div key={index} className="relative">
                <img
                  src={image.preview}
                  alt={`Preview ${index + 1}`}
                  className={`w-full h-32 object-cover rounded-md ${
                    index === 0 ? "border-4 border-green-button-color" : ""
                  }`}
                />
                <button
                  type="button"
                  onClick={() => removeImage(index)}
                  className="absolute top-1 right-1 text-red-500 hover:text-red-700"
                >
                  <XCircleIcon className="h-6 w-6" />
                </button>
                {index === 0 && (
                  <span className="absolute bottom-1 left-1 bg-green-button-color text-cosmic-latte text-xs px-2 py-1 rounded-md">
                    Fremhævet
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-primary-green">
            Kontaktoplysninger
          </h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label
                htmlFor="contactInfo.name"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Navn
              </label>
              <input
                type="text"
                id="contactInfo.name"
                name="contactInfo.name"
                value={formData.contactInfo.name}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-button-color"
              />
            </div>
            <div>
              <label
                htmlFor="contactInfo.city"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                By
              </label>
              <input
                type="text"
                id="contactInfo.city"
                name="contactInfo.city"
                value={formData.contactInfo.city}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-button-color"
              />
            </div>
            <div>
              <label
                htmlFor="contactInfo.zipCode"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Postnummer
              </label>
              <input
                type="text"
                id="contactInfo.zipCode"
                name="contactInfo.zipCode"
                value={formData.contactInfo.zipCode}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-button-color"
              />
            </div>
            <div>
              <label
                htmlFor="contactInfo.phone"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Telefon
              </label>
              <input
                type="tel"
                id="contactInfo.phone"
                name="contactInfo.phone"
                value={formData.contactInfo.phone}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-button-color"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="contactInfo.email"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Email
            </label>
            <input
              type="email"
              id="contactInfo.email"
              name="contactInfo.email"
              value={formData.contactInfo.email}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-button-color"
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full bg-green-button-color text-cosmic-latte px-6 py-3 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-lg font-semibold ${
              isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isSubmitting ? "Opretter opslag..." : "Opret opslag"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateUserPostForm;

import React, { useState, useEffect } from "react";
import { useCart } from "../contexts/CartContext";
import { useAuth0 } from "@auth0/auth0-react";
import CartReview from "../components/Checkout/CartReview";
import ShippingForm from "../components/Checkout/ShippingForm";
import OrderConfirmation from "../components/Checkout/OrderConfirmation";
import LoginPrompt from "../components/Checkout/LoginPrompt";
import { calculateHmacSha1 } from "../utils/hmacCalculation";

const STORAGE_KEY = "weLoveBirdsCheckoutInfo";

const CheckoutPage = () => {
  const { state: cartState, dispatch } = useCart();
  const { isAuthenticated, user } = useAuth0();
  const [step, setStep] = useState(1);
  const [shippingInfo, setShippingInfo] = useState(() => {
    const savedInfo = localStorage.getItem(STORAGE_KEY);
    return savedInfo
      ? JSON.parse(savedInfo)
      : {
          fullName: "",
          address: "",
          city: "",
          postalCode: "",
          country: "Danmark",
          email: "",
          sameAsBilling: true,
          billingAddress: "",
          billingCity: "",
          billingPostalCode: "",
          billingCountry: "Danmark",
        };
  });
  const [onPayParams, setOnPayParams] = useState(null);

  useEffect(() => {
    if (isAuthenticated && user) {
      setShippingInfo((prevInfo) => ({
        ...prevInfo,
        fullName: user.name || prevInfo.fullName,
        email: user.email || prevInfo.email,
      }));
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(shippingInfo));
  }, [shippingInfo]);

  const totalPrice = cartState.items.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
  const shippingCost = 0;
  const totalWithShipping = totalPrice + shippingCost;

  const handleShippingInfoChange = (e) => {
    const { name, value, type, checked } = e.target;
    setShippingInfo((prevInfo) => ({
      ...prevInfo,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleNextStep = () => {
    if (step === 2 && !isAuthenticated) {
      alert("Du skal være logget ind for at fortsætte til betaling.");
      return;
    }
    if (step === 2) {
      preparePayment();
    }
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const preparePayment = () => {
    const gatewayId = process.env.REACT_APP_ONPAY_GATEWAY_ID;
    const secret = process.env.REACT_APP_ONPAY_SECRET;
    const currency = "DKK";
    const amount = Math.round(totalWithShipping * 100).toString();
    const reference = `ORDER-${Date.now()}`;
    const acceptUrl = `${window.location.origin}/order-confirmation`;

    const params = {
      onpay_gatewayid: gatewayId,
      onpay_currency: currency,
      onpay_amount: amount,
      onpay_reference: reference,
      onpay_accepturl: acceptUrl,
    };

    console.log("Params before HMAC calculation:", params);
    console.log("Secret used for HMAC:", secret);

    const hmacSha1 = calculateHmacSha1(params, secret);

    console.log("Calculated HMAC:", hmacSha1);

    setOnPayParams({
      gatewayId: params.onpay_gatewayid,
      currency: params.onpay_currency,
      amount: params.onpay_amount,
      reference: params.onpay_reference,
      acceptUrl: params.onpay_accepturl,
      hmacSha1: hmacSha1,
    });
  };

  const renderStepIndicator = () => (
    <div className="flex justify-center mb-8">
      {[1, 2, 3].map((stepNumber) => (
        <div key={stepNumber} className="flex items-center">
          <div
            className={`w-8 h-8 rounded-full flex items-center justify-center ${
              step >= stepNumber
                ? "bg-green-button-color text-cosmic-latte"
                : "bg-gray-200 text-gray-600"
            }`}
          >
            {stepNumber}
          </div>
          {stepNumber < 3 && (
            <div
              className={`w-16 h-1 ${
                step > stepNumber ? "bg-green-button-color" : "bg-gray-200"
              }`}
            />
          )}
        </div>
      ))}
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8 max-w-5xl">
      <h1 className="text-3xl font-bold text-primary-green mb-8 text-center">
        Checkout
      </h1>
      {renderStepIndicator()}
      {step === 1 && (
        <div className="bg-cosmic-latte p-6 rounded-lg shadow-md">
          <CartReview
            cartItems={cartState.items}
            dispatch={dispatch}
            handleNextStep={handleNextStep}
          />
        </div>
      )}
      {step === 2 && (
        <>
          {isAuthenticated ? (
            <ShippingForm
              shippingInfo={shippingInfo}
              handleShippingInfoChange={handleShippingInfoChange}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
              userInfo={user}
            />
          ) : (
            <LoginPrompt />
          )}
        </>
      )}
      {step === 3 && (
        <OrderConfirmation
          shippingInfo={shippingInfo}
          cartItems={cartState.items}
          totalPrice={totalPrice}
          shippingCost={shippingCost}
          totalWithShipping={totalWithShipping}
          onPayParams={onPayParams}
          handlePreviousStep={handlePreviousStep}
        />
      )}
    </div>
  );
};

export default CheckoutPage;
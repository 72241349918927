import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { CogIcon } from "@heroicons/react/24/outline";
import UserInfo from "../components/Profile/UserInfo";
import UserPosts from "../components/Profile/UserPosts";
import PurchaseHistory from "../components/Profile/PurchaseHistory";
import DeleteAccount from "../components/Profile/DeleteAccount";
import UsernameGenerator from "../components/Profile/UsernameGenerator";

const ProfilePage = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const [activeTab, setActiveTab] = useState("info");
  const [showAdditionalSettings, setShowAdditionalSettings] = useState(false);
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Her ville du normalt hente brugernavnet fra din backend
    // For nu, lad os bare bruge nickname fra Auth0, hvis det findes
    if (user?.nickname) {
      setUsername(user.nickname);
    }
  }, [user]);

  if (isLoading) {
    return <div className="text-center py-10">Indlæser profil...</div>;
  }

  if (!isAuthenticated) {
    return (
      <div className="text-center py-10 text-red-500">
        Venligst log ind for at se din profil.
      </div>
    );
  }

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    navigate("/");
  };

  const handleSaveUsername = (newUsername) => {
    setUsername(newUsername);
    // Her ville du normalt gemme det nye brugernavn i din backend
    console.log("Nyt brugernavn gemt:", newUsername);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-cosmic-latte rounded-lg shadow-lg overflow-hidden">
        <div className="bg-light-green-50 p-6 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-primary-green">Min Profil</h1>
          <button
            onClick={() => setShowAdditionalSettings(!showAdditionalSettings)}
            className="border-2 border-primary-green text-primary-green px-4 py-2 rounded-lg hover:bg-primary-green hover:text-cosmic-latte transition-colors duration-200 flex items-center justify-center"
          >
            <span className="hidden md:inline mr-3">
              Yderligere indstillinger
            </span>
            <CogIcon className="h-5 w-5" />
          </button>
        </div>
        <div className="p-6">
          <div className="mb-6">
            <h2 className="text-xl font-semibold text-primary-green mb-2">Dit Fugle-brugernavn</h2>
            <UsernameGenerator 
              initialUsername={username} 
              onSaveUsername={handleSaveUsername}
            />
          </div>

          <div className="flex mb-6 space-x-4">
            <button
              className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
                activeTab === "info"
                  ? "bg-green-button-color text-cosmic-latte"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
              onClick={() => setActiveTab("info")}
            >
              Profiloplysninger
            </button>
            <button
              className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
                activeTab === "posts"
                  ? "bg-green-button-color text-cosmic-latte"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
              onClick={() => setActiveTab("posts")}
            >
              Mine Opslag
            </button>
            <button
              className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
                activeTab === "purchases"
                  ? "bg-green-button-color text-cosmic-latte"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
              onClick={() => setActiveTab("purchases")}
            >
              Købshistorik
            </button>
          </div>

          {activeTab === "info" && <UserInfo user={user} preferredUsername={username} />}
          {activeTab === "posts" && <UserPosts userId={user.sub} />}
          {activeTab === "purchases" && <PurchaseHistory userId={user.sub} />}

          <div className="mt-8 flex justify-between items-center">
            <button
              onClick={handleLogout}
              className="bg-primary-green text-cosmic-latte px-6 py-3 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-lg font-semibold"
            >
              Log ud
            </button>
            {showAdditionalSettings && <DeleteAccount />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  HomeIcon,
  ShoppingBagIcon,
  NewspaperIcon,
  ChatBubbleLeftRightIcon,
  Bars3Icon,
  XMarkIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";

function SideNavigation() {
  const [isOpen, setIsOpen] = useState(false);

//Jeg har lukket for nogle links for at stille og rolig opstart

  const navigationItems = [
    { to: "/", icon: HomeIcon, text: "Forside" },
    { to: "/shop", icon: ShoppingBagIcon, text: "Webshop" },
  //  { to: "/blog", icon: NewspaperIcon, text: "Blog" },
   // { to: "/user-posts", icon: ChatBubbleLeftRightIcon, text: "Opslag" },
   // { to: "/kontakt", icon: EnvelopeIcon, text: "Kontakt" },
  ];

  const additionalLinks = [
   // { to: "/samarbejdspartner", text: "Samarbejdspartnere" },
   // { to: "/privatlivspolitik", text: "Privatlivspolitik" },
    { to: "/betingelser", text: "Betingelser" },
   // { to: "/om-os", text: "Om os" },
  ];

  return (
    <>
      <nav className="fixed bottom-0 left-0 right-0 md:top-24 md:left-0 md:right-auto md:bottom-auto flex md:flex-col items-center justify-around md:items-start md:justify-start md:space-y-3 sm:space-y-4 z-50 py-2 md:py-0 md:pl-4 sm:pl-6 md:pr-2">
        {/* Gradient background for mobile */}
        <div className="absolute inset-x-0 bottom-0 h-full bg-gradient-cosmic-latte md:hidden"></div>
        
        {navigationItems.map(({ to, icon: Icon, text }) => (
          <NavLink
            key={to}
            to={to}
            className="flex flex-col items-center group w-16 md:w-12 sm:w-14 relative z-10"
          >
            {({ isActive }) => (
              <>
                <div className="relative mb-1">
                  <div
                    className={`absolute inset-0 rounded-full scale-125 transition-colors duration-300 ${
                      isActive ? "bg-primary-green/70" : "bg-light-green-50"
                    }`}
                  ></div>
                  <div className="relative bg-cosmic-latte p-1.5 sm:p-2 rounded-full shadow-lg hover:shadow-xl transition-shadow duration-300">
                    <Icon className="h-5 w-5 sm:h-6 sm:w-6 md:h-8 md:w-8 text-primary-green" />
                  </div>
                </div>
                <span className="text-[11px] sm:text-xs md:text-sm font-medium text-primary-green text-center leading-tight">
                  {text}
                </span>
              </>
            )}
          </NavLink>
        ))}

        {/* Burger Menu Button */}
        <button
          onClick={() => setIsOpen(true)}
          className="flex flex-col items-center group text-primary-green w-16 md:w-12 sm:w-14 relative z-10"
        >
          <div className="relative mb-1">
            <div className="absolute inset-0 bg-light-green-50 rounded-full scale-125"></div>
            <div className="relative bg-cosmic-latte p-1.5 sm:p-2 rounded-full shadow-lg hover:shadow-xl transition-shadow duration-300">
              <Bars3Icon className="h-5 w-5 sm:h-6 sm:w-6 md:h-8 md:w-8 text-primary-green" />
            </div>
          </div>
          <span className="text-[11px] sm:text-xs md:text-sm font-medium text-center leading-tight">Menu</span>
        </button>
      </nav>

      {/* Sidepanel Menu */}
      <div
        className={`fixed inset-y-0 left-0 w-64 bg-cosmic-latte shadow-2xl transform transition-transform duration-300 ease-in-out z-[60] ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="p-6">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-2xl font-semibold text-primary-green">Menu</h2>
            <button
              onClick={() => setIsOpen(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
          <div className="space-y-4">
            {additionalLinks.map(({ to, text }) => (
              <Link
                key={to}
                to={to}
                className="block py-2 px-4 text-primary-green hover:bg-light-green-50 rounded transition-colors duration-200"
                onClick={() => setIsOpen(false)}
              >
                {text}
              </Link>
            ))}
          </div>
        </div>
      </div>

      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50"
          onClick={() => setIsOpen(false)}
        ></div>
      )}
    </>
  );
}

export default SideNavigation;
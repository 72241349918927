import CryptoJS from 'crypto-js';

export const calculateHmacSha1 = (params, secret) => {
  const sortedParams = Object.keys(params)
    .filter(key => key.startsWith('onpay_') && key !== 'onpay_hmac_sha1')
    .sort()
    .reduce((obj, key) => {
      obj[key] = params[key];
      return obj;
    }, {});

  const queryString = Object.entries(sortedParams)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')
    .toLowerCase();

  return CryptoJS.HmacSHA1(queryString, secret).toString();
};
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "./components/common/Header";
import HomePage from "./pages/HomePage";
import ShopPage from "./pages/ShopPage";
import BlogPage from "./pages/BlogPage";
import BlogPostPage from "./pages/BlogPostPage";
import UserPostsPage from "./pages/UserPostsPage";
import ProductDetailPage from "./pages/ProductDetailPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ProfilePage from "./pages/ProfilePage";
import { CartProvider, useCart } from "./contexts/CartContext";
import CheckoutPage from "./pages/CheckoutPage";
import ZooSponsorPage from "./pages/ZooSponsorPage";
import ContactPage from "./pages/ContactPage";
import ProtectedRoute from "./components/common/ProtectedRoute";
import NotFound from "./components/NotFound";
import TermsPage from "./pages/TermsPage";
import AboutUsPage from "./pages/AboutUsPage";
import OrderConfirmationPage from "./pages/OrderConfirmationPage";
import { createUser } from "./services/api";

// Import background images
import bgImageDesk from "./assets/images/bgimagedesk.jpg";
import bgImagePhone from "./assets/images/bgimagephone.jpg";

// Create a styled component for the background
const BackgroundContainer = styled.div`
  min-height: 100vh;
  background-image: url(${bgImageDesk});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;

  @media (max-width: 768px) {
    background-image: url(${bgImagePhone});
    background-position: right top;
    background-size: auto 100%;
  }
`;

// Create a styled component for the main content
const MainContent = styled.main`
  position: relative;
  z-index: 20;
  padding-top: 160px;
  padding-bottom: 80px;

  @media (max-width: 480px) {
    padding-top: 120px;
  }

  @media (min-width: 768px) {
    padding-bottom: 0;
  }
`;

function AppContent() {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const { dispatch } = useCart();

  useEffect(() => {
    if (isAuthenticated && !isLoading && user) {
      const createUserInSanity = async () => {
        try {
          await createUser(user);
        } catch (error) {
          console.error("Failed to create user in Sanity:", error);
          // You might want to add some error handling here
        }
      };
      createUserInSanity();
    }
  }, [isAuthenticated, isLoading, user]);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      const storedCart = localStorage.getItem("weLoveBirdsCart");
      if (storedCart) {
        const parsedCart = JSON.parse(storedCart);
        dispatch({ type: "LOAD_CART", payload: parsedCart.items });
      }
    }
  }, [isAuthenticated, isLoading, dispatch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <BackgroundContainer>
        <Header />
        <MainContent className="container mx-auto p-4">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/shop" element={<ShopPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:slug" element={<BlogPostPage />} />
            <Route path="/user-posts" element={<UserPostsPage />} />
            <Route path="/product/:id" element={<ProductDetailPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/zoo-samarbejde" element={<ZooSponsorPage />} />
            <Route path="/kontakt" element={<ContactPage />} />
            <Route path="/betingelser" element={<TermsPage />} />
            <Route path="/om-os" element={<AboutUsPage />} />
            <Route
              path="/order-confirmation"
              element={<OrderConfirmationPage />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </MainContent>
      </BackgroundContainer>
    </Router>
  );
}

function App() {
  return (
    <CartProvider>
      <AppContent />
    </CartProvider>
  );
}

export default App;
